.node {
  border: 1px solid #45ce4e;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  padding: 12px;
  min-width: 300px;
  max-width: 300px;
  font-size: 30px;
  height: 110px;
  min-height: 110px;
  max-height: 110px;
  text-align: center;
  font-weight: bold;
  line-height: 1.2;
}

.node:hover {
  background: radial-gradient(circle at 18.7% 37.8%, rgb(252, 252, 252) 0%, rgb(239, 241, 243) 90%);
}

.content {
  pointer-events: none;
}

.handle {
  visibility: hidden;
}

.placeholder {
  width: 255px;
  background: #fff;
  border: 1px dashed #bbb;
  color: #bbb;
  box-shadow: none;
}
