.edgeButton {
  cursor: pointer;
  pointer-events: all;
  stroke: #45CE4E ;
  fill: white;
}

.edgeButton:hover {
  fill: #f5f5f5;
}

.edgeButtonText {
  pointer-events: none;
  user-select: none;
  fill: #45CE4E ;
}

.edgePath {
  fill: none;
  stroke: #45CE4E ;
  stroke-width: 0.8;
}

.placeholderPath {
  stroke-width: 1;
  stroke-dasharray: 3 3;
  stroke: #bbb;
  fill: none;
}
